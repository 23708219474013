import React from 'react'
import { useEffect } from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import { EventBlog } from '../Components/EventBlog/EventBlog'
import tbi_b from '../Components/Assets/tbi_b.png'
import jnv from "../Components/Assets/jnv.jpg"
import cof1 from "../Components/Assets/2july.jpeg"
import cof2 from "../Components/Assets/cof2.JPG"
import cof3 from "../Components/Assets/cof3.jpeg"
import cof5 from "../Components/Assets/cof5.jpg"
import cof01 from '../Components/Assets/cof01.jpg'
import DSVV from '../Components/Assets/DSVV.jpg'
import IITR1 from '../Components/Assets/IITR1.jpg'
import kiet from '../Components/Assets/kiet.jpg'
import GTU from '../Components/Assets/GTU.jpg'
import EDII from '../Components/Assets/EDII.jpg'
  const OtherEvents = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const events = [
      {
        url: EDII,
        description: `CrAdLE, a Section 8 company under the Companies Act 2013, fosters technology and knowledge-based startups, with its office located at the Entrepreneurship Development Institute of India, Gandhinagar.`,
        date: `15 October 2024`
      },
      {
        url: GTU,
        description: `GTU Innovation Council and MNNIT's IIHMF have partnered to boost innovation and entrepreneurship through joint programs, knowledge sharing, and initiatives, fostering startup growth and empowering entrepreneurial minds across India.`,
        date: `15 October 2024`
      },
      {
        url: kiet,
        description: `TBI-KIET, established in 2007 with DST support, promotes first-generation entrepreneurs. Self-sustaining since 2012, it has shown growth in startup incubation, mentoring, and graduation, fostering innovation nationwide.`,
        date: `14 October 2024`
      },
      {
        url: IITR1,
        description: `MNNIT's Innovation Hub partnered with IIT Roorkee's TIDES to boost startups, offering joint programs, mentorship, and funding, fostering innovation, and supporting Aatmanirbhar Bharat through entrepreneurship and local employment opportunities.`,
        date: `1 October 2024`
      },
      {
        url: DSVV,
        description: `Dev Sanskriti Vishwavidyalaya and MNNIT's Innovation Hub signed an MoU to boost collaboration in education, health, and management, focusing on research, innovation, and student exchange for societal upliftment.`,
        date: `1 October 2024`
      },
      {
        url: cof01,
        description: `IIHMF of MNNIT and NIEF of the University of Allahabad have signed a Memorandum of Understanding (MOU) for mutual support towards the development of entrepreneurship and the startup ecosystem.  `,
        date: `11 july 2024`
      },
      {
        url: cof1,
        description: ` Prof. Arun K. Thittai's session at IIHMF focused on AI in medical equipment development.`,
        date: `2 july 2024`
      },{
        url: cof2,
        description: ` Mr. Abhishek Tiwari from KPMG discussed various modes of financial assistance to the Incubator and Startups`,
        date: `7 June 2024`
      },{
        url: cof3,
        description: `On World Environment Day, June 5, IIHMF celebrated by planting trees.`,
        date: `5 June 2024`
      },
      {
        url: tbi_b,
        description:`IITs and NITs support startups to realize Viksit Bharat 2047. Prof. Rajiv Prakash of IIT Bhilai shared insights on innovation at IIHMF, MNNIT Prayagraj, focusing on FinTech, AgriTech, and IoT.`,
        date: `30 May 2024`
      },
      {
        url: jnv,
        description: 'InnovateMed Insights: A journey through bench to beside excellence with Dr. Jitendra N Verma”',
        date: `4 February 2024`
      },
      {
        url: cof5,
        description: 'Innodrive 2024, pioneering the future of mobility with BS VI Technology.',
        date: `31 January 2024 `
      }
    ];
    console.log("g",tbi_b)
  return (

    <div className='other-events'>
      <Navbar />
      <EventBlog events={events}  />
      <Footer />
    </div>
  )
}

export default OtherEvents
