// import React from 'react';
// import "./EventBlog.css";

// export const EventBlog = (props) => {
//     return (
//         <div className='event-blog'>
//             <div className="event-blog-head"><h1>Other events</h1></div>
//             <div className="container">
//                 {props.events.map((event, index) => (
//                     <div key={index} className="event-item">
//                         <div className="img">
//                             <img src= {event.url} alt={`Event ${index + 1}`} className="event-image" />
//                         </div>
//                         <div className="desc">
//                             <p>{event.description}</p>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }



import React from 'react';
import "./EventBlog.css";

export const EventBlog = (props) => {
    return (
        <div className='event-blog'>
            <div className="event-blog-head"><h1>News/Events</h1></div>
            <div className="container">
                {props.events.map((event, index) => (
                    <div key={index} className="event-item">
                        <div className="img">
                            <img src={event.url} alt={`Event ${index + 1}`} className="event-image" />
                        </div>
                        <div className="desc">
                            <p>{event.description}</p>
                        </div>
                        <div className="date">
                            {event.date}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
