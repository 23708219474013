import React from "react";
import "./Carousel.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import ban11 from '../Assets/ban11.jpg';
import c2 from '../Assets/c2.jpeg';
import c3 from '../Assets/c3.jpeg';
import udh from '../Assets/udh.jpg';
import dir from '../Assets/dir.jpeg'

const Carousel = () => {
  return (
    <div className="carousel">
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="3000"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={dir} className="d-block w-100" alt="Slide 1" />
            <div className="carousel-caption">
              <h1 style={{color: '#2e282a'}}>Prof. R.S.Verma </h1>
              <h4 style={{color: '#2e282a'}}>Director's message coming soon... </h4>
              <p></p>
            </div>
          </div>
          <div className="carousel-item ">
            <img src={ban11} className="d-block w-100" alt="Slide 1" />
            <div className="carousel-caption">
              <h1 style={{color: '#2e282a'}}>Fostering Innovations </h1>
              <p></p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={c2} className="d-block w-100" alt="Slide 2" />
            <div className="carousel-caption">
              <h1 id="udbhav-cap">UDBHAV  </h1>
              <p>Youth summit</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={c3} className="d-block w-100" alt="Slide 3" />
            <div className="carousel-caption">
              <h1>TEDx</h1>
              <p></p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={udh} className="d-block w-100" alt="Slide 3" />
            <div className="carousel-caption">
              <h1 style={{color: '#fff'}}>Udbhav</h1>
              <p></p>
            </div>
          </div>
          {/* <div className="carousel-item">
            <img src={tbi_b} className="d-block w-100" alt="Slide 3" />
            <div className="carousel-caption">
              <h1 style={{color: '#000'}}>Fostering Innovations</h1>
              <p></p>
            </div>
          </div> */}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;