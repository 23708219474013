import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './EventGallery.css';

const EventGallery = (props) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const intervalRef = useRef(null);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : props.images.length - 3));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < props.images.length - 3 ? prevIndex + 1 : 0));
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      goToNext();
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(intervalRef.current);
  }, [currentIndex]);

  return (
    <div className='event-gallery'>
      <div className="event-gallery-head">
        <h1>EVENT GALLERY</h1>
      </div>
      <div className="event-gallery-controls">
        <button className="slider-button prev" onClick={goToPrevious}>&lt;</button>
        <div className="event-images">
          {props.images.map((url, index) => (
            <div
              key={index}
              className={`event-image-container ${index === currentIndex ? 'active' : ''}`}
              style={{ transform: `translateX(-${currentIndex * 100}%) `}}
            >
              <img src={url} alt={`Event ${index + 1}`} className="event-image" />
            </div>
          ))}
        </div>
        <button className="slider-button next" onClick={goToNext}>&gt;</button>
      </div>
    </div>
  );
}

EventGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EventGallery;