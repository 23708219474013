import React from 'react'
import './Announcements.css'

const Announcements = () => {
  return (
    <div className='announcements'>
        <div className="announcement-title">
            <p>Announcements</p>
        </div>
      <ul>
        <li> <a href="http://www.mnnit.ac.in/tbi/notifications/IIHMF-TBI%20Advertisement%2010.06.2024-Pub.pdf">Advertisement No. TBI/MNNIT/RECT/02, dated 10/06/2024, regarding various position in Innovation and Incubation Hub MNNIT Foundation..</a> </li>
        {/* <li>Lorem ipsum dolor sit amet.</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum deleniti eius ab enim!</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li> */}
      </ul>
    </div>
  )
}

export default Announcements
