import React from 'react'
import { useEffect } from 'react'
import './CSS/TeamPage.css'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import ProfileCard from '../Components/ProfileCard/ProfileCard'
import suraj from '../Components/Assets/suraj.jpg'
import Sakshi from '../Components/Assets/sakshi.jpg'
import d1 from "../Components/Assets/team1.jpg"
import d2 from "../Components/Assets/team-2.jpg"
import d3 from "../Components/Assets/team-3.jpg"
import d4 from "../Components/Assets/team-4.jpg"
import d5 from "../Components/Assets/team-5.jpg"
import d6 from "../Components/Assets/team-6.jpg"
import m1 from "../Components/Assets/m1.jpeg"
import con1 from "../Components/Assets/con1.jpeg"
import con2 from "../Components/Assets/con2.jpeg"
import con3 from "../Components/Assets/con3.jpeg"
import con4 from "../Components/Assets/con4.jpg"
import ram from "../Components/Assets/ram.jpg"
import man from "../Components/Assets/man.jpg"
import sanjay from "../Components/Assets/sanjay.jpg"
import ravi from "../Components/Assets/ravi.jpg"
const TeamPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='team-page'>
      <Navbar />
      <div className="chairmen-director">
      <div className="chairmen-director-head"><div className="directors-head">
          <h1>Director MNNIT </h1>
        </div>
        <div className="director-cards">
          <ProfileCard img={d1} name="Prof. R.S.Verma" faculty_profile="http://www.mnnit.ac.in/index.php/institute/437-profile-prof-rs-verma" email="director@mnnit.ac.in" gscholar="https://scholar.google.co.in/citations?user=LrxrULcAAAAJ&hl=en" desc="Dr. Rama Shanker Verma, Director at MNNIT, is a renowned biotechnologist with extensive academic and research achievements." />
          </div>
        </div>
        <hr /></div>
      <div className="directors">
        <div className="directors-head">
          <h1>Board Of Directors</h1>
        </div>
        <div className="director-cards">
          {/* <ProfileCard img={d1} name="Prof. R.S.Verma" faculty_profile="http://www.mnnit.ac.in/index.php/institute/437-profile-prof-rs-verma" email="director@mnnit.ac.in" gscholar="https://scholar.google.co.in/citations?user=LrxrULcAAAAJ&hl=en" desc="Dr. Rama Shanker Verma, Director at MNNIT, is a renowned biotechnologist with extensive academic and research achievements." /> */}
          <ProfileCard img={d2} name="Prof. R.P.Tewari " faculty_profile="http://mnnit.ac.in/profile/rptewari" email="rptewari@mnnit.ac.in" gscholar="" desc="Dr. Ravi Prakash Tewari, MNNIT Allahabad Professor, specializes in biomechanics, robotics, and biomaterials research."/>
          <ProfileCard img={d3} name="Prof. Ramesh Pandey" faculty_profile="http://mnnit.ac.in/profile/ramesh" email="ramesh@mnnit.ac.in" gscholar="https://scholar.google.co.in/citations?hl=en&user=ApZfLYAAAAAJ" desc="Prof. Ramesh Pandey, MNNIT Professor, specializes in structural analysis and composite structures with extensive teaching experience." />
          <ProfileCard img={d5} name="Prof. Basant Kumar" faculty_profile="http://mnnit.ac.in/profile/singhbasant" email="singhbasant@mnnit.ac.in" gscholar="https://scholar.google.co.in/citations?hl=en&user=JQHgzL0AAAAJ" linkedin="https://www.linkedin.com/in/basant-kumar-9808476b/" desc="Prof. Basant Kumar, MNNIT Professor, specializes in digital communication and biomedical signal processing with extensive teaching experience." />
          <ProfileCard img={d4} name="Prof. Animesh Kumar Ojha" faculty_profile="http://mnnit.ac.in/profile/animesh" email="animesh@mnnit.ac.in" gscholar="https://scholar.google.co.in/citations?hl=en&user=SaWuUsMAAAAJ" desc="Prof. Animesh Kumar, MNNIT Professor, specializes in experimental and theoretical condensed matter physics with extensive research experience." />
          <ProfileCard img={d6} name="Dr. Anubhav Rawat" faculty_profile="http://mnnit.ac.in/profile/anubhav-r" email="anubhav-r@mnnit.ac.in" gscholar="https://scholar.google.co.in/citations?user=svrbtdcAAAAJ&hl=en&oi=ao" linkedin="https://www.linkedin.com/in/dr-anubhav-rawat-73ab84a7/" desc="Dr. Anubhav Rawat, MNNIT Assistant Professor, specializes in fluid mechanics and multiphase flows with extensive teaching experience." />
        </div>
        <hr />
        <div className="mentors">
          <div className="mentors-head">
            <h1>Our Mentors</h1>
          </div>
          <div className="mentor-cards">
            <ProfileCard img={m1} name="Chintan Oza" linkedin="https://www.linkedin.com/in/chintanoza/"  desc="Founder of Anantam Ecosystems, helping startups with strategy, fundraising, and growth to IPO or acquisition." />
          </div>
        </div>
        <hr />
        <div className="consultant">
          <div className="consultant-head">
            <h1>Our Consultants</h1>
          </div>
          <div className="consultant-cards">
            <ProfileCard img={con1} name="Arvind Verma" linkedin="https://www.linkedin.com/in/arvindvehiclecare/"  desc="CEO of Vehiclecare, leading a team to deliver quality car service solutions and mentoring tech startups." />
            <ProfileCard img={con2} name="Vishal Kumar Gaur" faculty_profile="http://mnnit.ac.in/profile/vkgaur" email="vkgaur@mnnit.ac.in" gscholar=""  desc="Vishal Kumar Gaur, Assistant Professor at MNNIT Allahabad, specializes in power electronics, control systems, and electrical machines."/>
            <ProfileCard img={con3} name="Ambak Kumar Rai" faculty_profile="http://www.mnnit.ac.in/profile/previewr.php?id=1466" email="ambakrai@mnnit.ac.in" gscholar="" desc="Ambak Kumar Rai, Associate Professor at MNNIT Allahabad, excels in biotechnology and holds diverse administrative roles." />
            <ProfileCard img={con4} name="DR. J. C. ATWAL" faculty_profile="http://mnnit.ac.in/profile/jcatwal" email="jcatwal@mnnit.ac.in" gscholar="" desc="Assistant Professor at MNNIT Allahabad, excels in mechanical engineering with expertise in tribology and computational design." />
           </div>
           <hr />
        </div>
        <hr />
        <div className="team">
          <div className="team-head">
            <h1>Our Team</h1>
          </div>
          <div className="team-cards">
           <ProfileCard img={sanjay} name="Dr. Sanjay Kumar (CEO) " linkedin="https://www.linkedin.com/in/startwithsanjay/"  desc="International Start-Up Ecosystem Builder|Mentor at Faster Capital Dubai, Start-up India & Wadhwani Foundation"  email="ceoiihmf@gmail.com" />
            <ProfileCard img={ram} name="Dr. Ram Kumar Mishra (Senior-Manager) " linkedin="https://www.linkedin.com/in/dr-ram-kumar-mishra-884b1996/"  desc="12+ years in corporate services, expert in PSUs and private sector, multiple academic qualifications." />
            <ProfileCard img={man} name="CS Pradeep Singh (Manager)" email="pradeepdhatacs@gmail.com" gscholar=""  desc="Commerce postgraduate, ICSI associate member, and Company Secretary with 4+ years of corporate law experience."/>
            <ProfileCard img={ravi} name="Ravi pratap pandey (Incubation Associate )" gscholar=""  desc="8+ years of corporate experience with involvement in multiple government projects." email="ambitiousravi5@gmail.com" linkedin="https://www.linkedin.com/in/ravi-pandey-68780559/"/>
            <ProfileCard img={suraj} name="Suraj Tiwari (Accountant)" email="tisuraj91@gmail.com" gscholar=""  desc="B.Com,MBA,CA(Inter)"/>
            <ProfileCard img={Sakshi} name="Sakshi (Office Assistant)" email="sharmasakshi02514@gmail.com" gscholar=""  desc=" B.sc completed M.sc under process"/>
            {/* <ProfileCard img={con3} name="Ambak Kumar Rai" faculty_profile="http://www.mnnit.ac.in/profile/previewr.php?id=1466" email="ambakrai@mnnit.ac.in" gscholar="" desc="Ambak Kumar Rai, Associate Professor at MNNIT Allahabad, excels in biotechnology and holds diverse administrative roles." />
            <ProfileCard img={con4} name="DR. J. C. ATWAL" faculty_profile="http://mnnit.ac.in/profile/jcatwal" email="jcatwal@mnnit.ac.in" gscholar="" desc="Dr. J. C. Atwal, Assistant Professor at MNNIT Allahabad, excels in mechanical engineering with expertise in tribology and computational design." /> */}
           </div>

        </div>
        
      </div>
      <Footer />
    </div>
  )
}

export default TeamPage
