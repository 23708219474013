import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './Pages/HomePage';
import AboutUsPage from './Pages/AboutUsPage';
import EventsPage from './Pages/EventsPage';
import RegistrationPage from './Pages/RegistrationPage';
import StartUpDir from './Pages/StartUpDir';
import TeamPage from './Pages/TeamPage';
import Careers from './Pages/Careers';
import Udbhav from './Pages/Udbhav';
import Ted from './Pages/Ted';
import Ren from './Pages/Ren';
import OtherEvents from './Pages/OtherEvents';
import Awards from './Pages/Awards';
import Events from './Pages/Events';
import Portfoilo from './Pages/Portfoilo';
import Overview from './Pages/Overview';
import WebTeamPage from './Pages/WebTeamPage';
import PreIncubated from './Pages/PreIncubated';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />}/>
          <Route path='/aboutus' element={<AboutUsPage />}/>
          <Route path='/team' element={<TeamPage />}/>
          <Route path='/events' element={<EventsPage />}/>
          <Route path='/registration' element={<RegistrationPage />}/>
          <Route path='/portfolio' element={<Portfoilo />}>
            <Route path='overview' element={<Overview />}/>
            <Route path='startup-dir' element={<StartUpDir />} />
            <Route path='PreIncubated' element={<PreIncubated />} />
          </Route>
          
          <Route path='/careers' element={<Careers />} />
          <Route path='/events' element={<Events/>}>
            <Route path='udbhav' element={<Udbhav />} />
            <Route path='ted' element={<Ted />} />
            <Route path='ren' element={<Ren />} />
            <Route path='other-events' element={<OtherEvents />} />
            <Route path='awards' element={<Awards />} />
        </Route>
        <Route path="/webteam" element={<WebTeamPage/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
