import React from 'react'
import { useEffect } from 'react'
import ProfileCard from '../Components/ProfileCard/ProfileCard'
import './CSS/WebTeamPage.css'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import me from '../Components/Assets/me.jpg'
import sajal from '../Components/Assets/sajal.jpg'
import dheeraj from '../Components/Assets/dheeraj.jpeg'
const WebTeamPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className='web-page'>
            <Navbar/>
            <div className="web-page-head">
                <h1>Meet Our Web Team</h1>
            </div>
            <div className="web-page-container">
                <ProfileCard name='Sajal Kumar Babu Degala' desc='Research Scholar
Department of Applied Mechanics
MNNIT Allahabad, Prayagraj' img={sajal}/>
                <ProfileCard name='Devanshi gupta' desc="BTech '26
                Production and Industrial Engineering MNNIT Allahabad, Prayagraj" img={me} linkedin="https://www.linkedin.com/in/devanshi0503/" />
                <ProfileCard name='Dheeraj Sharma' img={dheeraj} linkedin="https://www.linkedin.com/in/dheeraj5948/"desc="BTech '26
                Computer Science & Engineering MNNIT Allahabad, Prayagraj "/>
            </div>
            <Footer/>
        </div>
    )
}

export default WebTeamPage